import Config from "../config/config";
import {
  Button,
  Center,
  Checkbox,
  Group,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import axios from "axios";
import { useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EyeCheck, EyeOff } from "tabler-icons-react";
function ResetPassword() {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let token = searchParams.get("token");
    if (!token) {
      navigate("/login");
    }
  }, []);
  const form = useForm({
    initialValues: {
      password: "",
      cPassword: "",
    },
    validate: {
      password: (value: any) => {
        if (
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gm.test(
            value
          ) &&
          form.values.password === form.values.cPassword
        ) {
          return true;
        } else {
          form.setErrors({
            password: "Invalid password",
            cPassword: "Invalid password",
          });
          return false;
        }
      },
      cPassword: (value: any) => {
        if (
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gm.test(
            value
          ) &&
          form.values.password === form.values.cPassword
        ) {
          return true;
        } else {
          form.setErrors({
            password: "Invalid password",
            cPassword: "Invalid password",
          });
          return false;
        }
      },
    },
  });
  async function resetPassword(formValues) {
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/users/resetPassword`,
        {
          token: searchParams.get("token"),
          password: formValues.password,
        }
      );
      showNotification({
        id: "pass-reset",
        disallowClose: true,
        autoClose: 2000,
        title: "Reset Password",
        message: "Password changed successfully",
        color: "green",
        loading: false,
      });
      navigate("/login");
    } catch (err) {
      showNotification({
        id: "pass-reset",
        disallowClose: true,
        autoClose: 2000,
        title: "Reset Password",
        message: "Not Able to send email",
        color: "red",
        loading: false,
      });
    }
  }

  return (
    <>
      <div className="offset-7 col-4">
        <h3 className="text-primary text-center">Reset Password</h3>
        <div className="form-group mt-3">
          <form onSubmit={form.onSubmit((values) => resetPassword(values))}>
            <PasswordInput
              defaultValue="secret"
              visibilityToggleIcon={({ reveal, size }) =>
                reveal ? <EyeOff size={size} /> : <EyeCheck size={size} />
              }
              required
              label="Password"
              {...form.getInputProps("password")}
            />

            <PasswordInput
              required
              defaultValue="secret"
              visibilityToggleIcon={({ reveal, size }) =>
                reveal ? <EyeOff size={size} /> : <EyeCheck size={size} />
              }
              label="Confirm Password"
              {...form.getInputProps("cPassword")}
            />

            <Group position="right" mt="md">
              <Button className="bg-danger" type="submit">
                Submit
              </Button>
            </Group>
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
