import Config from "../config/config";
import { Button, Center, Checkbox, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import axios from "axios";
import { useState } from "react";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
function ForgetPassword() {
  let navigate = useNavigate();
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: (value: any) => {
         if(/^\S+@\S+$/.test(value)){
          return true
         } else {
          form.setErrors({
            email: 'Invalid Email'
          })
          return false
        };
      },
    },
  });
  async function sendResetPasswordEmail(formValues){
    try{
      let {data, status} = await axios.post(`${Config.baseUrl}/users/sendResetPasswordToken`,{
        email: formValues.email,
        osName: 'web',
        role: 1 // @TODO remove harding
      })
      navigate('/password-email-sent')
    }catch(err){
      showNotification({
        id: "pass-reset",
        disallowClose: true,
        autoClose: 2000,
        title: "Reset Password",
        message: 'Not Able to send email',
        color: "red",
        loading: false,
      });
    }
  }

  return (
    <>
      <div className="offset-7 col-4">
        <h3 className="text-primary text-center">Forgot your Password ?</h3>
        <div className="form-group mt-3">
          <form onSubmit={form.onSubmit((values) => sendResetPasswordEmail(values))}>
            <TextInput
              required
              label="Email"
              placeholder="your@email.com"
              {...form.getInputProps("email")}
            />

            <Group position="right" mt="md">
              <Button className="bg-danger" type="submit">
                Submit
              </Button>
            </Group>
          </form>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
