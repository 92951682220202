import React, { useEffect, useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Box,
  Group,
  UnstyledButton,
  Collapse,
  ThemeIcon,
} from "@mantine/core";
import logo from "../assets/images/logo-light.png";
import { Image } from "@mantine/core";
import useUserStore from "../store/user-store";
import { MainLinks } from "./main-links";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../store/app-store";
import { useErrandStore } from "../store/errand-store";
import { Activity, AddressBook, BrandStripe, CalendarTime, CashBanknote, Certificate2, Checklist, Download, Help, ListDetails, Logout, Truck, User } from "tabler-icons-react";
import { Certificate } from "crypto";

export default function CustomAppShell(props: any) {
  const { isLoggedIn, setLoggedIn, logout } = useAppStore();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const { resetErrandStore } = useErrandStore();
  let navigate = useNavigate();
  const [nav, setNav] = useState(1);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isMyAccount, setIsMyAccount] = useState(false);
  const navigateApp = (path) => {
    setOpened(false)
    setOpen(false);
    setIsMyAccount(false)
    navigate(path);
  }
  useEffect(() => {
    let path = location.pathname.substring(
      location.pathname.split("/", 2).join("/").length + 1,
      location.pathname.lastIndexOf("/")
    );
    if (path !== "create-errand") { window.Intercom('shutdown') }
    if (path === "create-errand") {
      setNav(1);
    } else if (path === "open-errand") {
      setNav(2);
    } else if (location.pathname.includes('completed-errand')) {
      setNav(3);
    } else if (path === "account") {
      setNav(4);
    } else {
      setNav(5);
    }
  }, [location]);
  return (
    <AppShell
      classNames={{
        main: "px-0",
      }}
      styles={{
        main: {
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={
        isLoggedIn ? (
          <MediaQuery largerThan="md" styles={{ display: "none" }}>
            <Navbar
              p="md"
              hiddenBreakpoint="sm"
              className={`${!opened ? "d-none" : ""}`}
              width={{ sm: 500, md: 300, lg: 700 }}
            >
              <Navbar.Section
                onClick={() => {
                  navigateApp("create-errand/select-type");
                }}
              >
                <UnstyledButton
                  sx={(theme) => ({
                    display: "block",
                    width: "100%",
                    padding: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                    color:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black,
                  })}
                >
                  <Group>
                    <ThemeIcon color={'blue'} variant="light">
                      <Truck />
                    </ThemeIcon>
                    <Text size="sm">Create Errand</Text>
                  </Group>
                </UnstyledButton>
              </Navbar.Section>
              <Navbar.Section>
                <UnstyledButton
                  onClick={() => {
                    setOpen(!open)
                  }}
                  sx={(theme) => ({
                    display: "block",
                    width: "100%",
                    padding: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                    color:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black,
                  })}
                >
                  <Group>
                    <ThemeIcon color={'teal'} variant="light">
                      <ListDetails />
                    </ThemeIcon>
                    <Text size="sm">Open</Text>
                  </Group>
                </UnstyledButton>
                <Collapse in={open} className='mx-5'>
                  <UnstyledButton
                    onClick={() => {
                      navigateApp('open-errand/in-progress')
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'violet'} variant="light">
                        <Activity />
                      </ThemeIcon>
                      <Text size="sm">In Progress</Text>
                    </Group>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() => {
                      navigateApp('open-errand/upcoming')
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'teal'} variant="light">
                        <CalendarTime />
                      </ThemeIcon>
                      <Text size="sm">Upcoming</Text>
                    </Group>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() => {
                      navigateApp('open-errand/incomplete')
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'grape'} variant="light">
                        <Download />
                      </ThemeIcon>
                      <Text size="sm">Incomplete</Text>
                    </Group>
                  </UnstyledButton>
                </Collapse>
              </Navbar.Section>
              <Navbar.Section
                onClick={() => {
                  setOpened(false)
                  navigateApp("completed-errand");
                }}
              >
                <UnstyledButton
                  sx={(theme) => ({
                    display: "block",
                    width: "100%",
                    padding: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                    color:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black,
                  })}
                >
                  <Group>
                    <ThemeIcon color={'grape'} variant="light">
                      <Checklist />
                    </ThemeIcon>
                    <Text size="sm">Completed</Text>
                  </Group>
                </UnstyledButton>
              </Navbar.Section>
              <Navbar.Section
                onClick={() => {
                  setIsMyAccount(!isMyAccount)
                }}
              >
                <UnstyledButton
                  sx={(theme) => ({
                    display: "block",
                    width: "100%",
                    padding: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                    color:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[0]
                        : theme.black,
                  })}
                >
                  <Group>
                    <ThemeIcon color={'teal'} variant="light">
                      <User />
                    </ThemeIcon>
                    <Text size="sm">My Account</Text>
                  </Group>
                </UnstyledButton>
                <Collapse in={isMyAccount} className="mx-5">
                  <UnstyledButton
                    onClick={() => {
                      navigateApp('account/profile')
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'violet'} variant="light">
                        <User />
                      </ThemeIcon>
                      <Text size="sm">Profile</Text>
                    </Group>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() => {
                      navigateApp('account/business-details')
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'grape'} variant="light">
                        <CashBanknote />
                      </ThemeIcon>
                      <Text size="sm">Business Details</Text>
                    </Group>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() => {
                      navigateApp('account/customer-list')
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'teal'} variant="light">
                        <AddressBook />
                      </ThemeIcon>
                      <Text size="sm">Customer list</Text>
                    </Group>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() => {
                      navigateApp('account/payment-details')
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'blue'} variant="light">
                        <BrandStripe />
                      </ThemeIcon>
                      <Text size="sm">Payment Details</Text>
                    </Group>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() => {
                      window.open(
                        'https://myerrand.co.uk/terms-of-use',
                        '_blank'
                      );
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'teal'} variant="light">
                        <Certificate2 />
                      </ThemeIcon>
                      <Text size="sm">Terms And Conditions</Text>
                    </Group>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() => {
                      window.open(
                        'https://myerrand.co.uk/privacy-notice/',
                        '_blank'
                      );
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'violet'} variant="light">
                        <Certificate2 />
                      </ThemeIcon>
                      <Text size="sm">Privacy Policy</Text>
                    </Group>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() => {
                      window.open(
                        'http://myerrand.co.uk/',
                        '_blank'
                      );
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'grape'} variant="light">
                        <Help />
                      </ThemeIcon>
                      <Text size="sm">Help</Text>
                    </Group>
                  </UnstyledButton>
                  <UnstyledButton
                    onClick={() => {
                      navigate("/");
                      localStorage.setItem('token', '')
                    }}
                    sx={(theme) => ({
                      display: "block",
                      width: "100%",
                      padding: theme.spacing.xs,
                      borderRadius: theme.radius.sm,
                      color:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[0]
                          : theme.black,
                    })}
                  >
                    <Group>
                      <ThemeIcon color={'blue'} variant="light">
                        <Logout />
                      </ThemeIcon>
                      <Text size="sm">Logout</Text>
                    </Group>
                  </UnstyledButton>
                </Collapse>
              </Navbar.Section>
            </Navbar>
          </MediaQuery>
        ) : (
          // <div ></div>
          <></>
        )
      }
      header={
        <Header classNames={{ root: "bg-primary" }} height={60} p="md">
          <div
            className="primary"
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            {isLoggedIn ? (
              <>
                <Image src={logo} alt="logo" className="cursor-pointer" height={50} width="auto" onClick={()=>{window.open('http://www.myerrand.co.uk', '_blank')}}></Image>
                <MediaQuery largerThan="md" styles={{ display: "none" }}>
                  <div
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <Burger
                      opened={opened}
                      onClick={() => setOpened((o) => !o)}
                      size="sm"
                      color={"#ebad1f"}
                      mr="xl"
                    />
                  </div>
                </MediaQuery>
                <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                  <div
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <ul
                      className="d-flex list-unstyled gap-4 header-nav"
                      style={{
                        marginLeft: "auto",
                        marginRight: "100px",
                        marginBottom: "0px",
                      }}
                    >
                      <li
                        className={`cursor-pointer text-white ${nav === 1 ? "active" : ""
                          }`}
                        onClick={() => {
                          resetErrandStore()
                          navigate("create-errand/select-type");
                        }}
                      >
                        Create Errand
                      </li>
                      <li
                        className={`cursor-pointer text-white ${nav === 2 ? "active" : ""
                          }`}
                        onClick={() => {
                          navigate("open-errand/in-progress");
                        }}
                      >
                        Open
                      </li>
                      <li
                        onClick={() => {
                          navigate("completed-errand");
                        }}
                        className={`cursor-pointer text-white ${nav === 3 ? "active" : ""
                          }`}
                      >
                        Completed
                      </li>
                      <li
                        className={`cursor-pointer text-white ${nav === 4 ? "active" : ""
                          }`}
                        onClick={() => {
                          navigate("account/profile");
                        }}
                      >
                        My Account
                      </li>
                    </ul>
                  </div>
                </MediaQuery>
              </>
            ) : (
              <Image onClick={()=>{window.open('http://www.myerrand.co.uk', '_blank')}} className={'cursor-pointer'} src={logo} alt="logo" height={50} width='auto'></Image>
            )}
          </div>
        </Header>
      }
    >
      {props.children}
    </AppShell>
  );
}
