import React, { useEffect } from "react";
import SideImage from "./components/side-image";
import "./index.scss";
import Logo from "./assets/images/logo.png";
import Login from "./components/login.component"; //'./assets/images/logo.png';
import Register from "./components/register.component";
import {
  Routes,
  Route,
  Router,
  HashRouter,
  useLocation,
} from "react-router-dom";
import RegisterBusiness from "./components/register-business.component";
import SentEmail from "./components/sent-email";
import Nav from "./components/nav.component";
import ErrorToasts from "./components/error-toasts";
import { Provider } from "react-redux";
import axios from "axios";
import Config from "./config/config";
import { useStore } from "./store/store";
import { GlobalEnums } from "./store/global-enums";
import { useNavigate } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ProtectedRoute } from "./components/protected-route.component";
import HoldingPage from "./components/holding-page.component";
import CustomAppShell from "./components/custom-app-shell";
import { MyerrandNav } from "./components/my-errand-nav";
import ForgetPassword from "./pages/forgot-password";
import PasswordEmailSent from "./pages/password-email-sent";
import ResetPassword from "./pages/reset-password";
import AxiosInstance from "./utils/axios-instance";
import VerifyPhoneOtp from "./components/verify-phone.component";
import PhoneVerified from "./components/phone-verified.component";

// import 'bootstrap/dist/css/bootstrap.min.css'
// import '../src/';

function App() {
  let { setEnums } = useStore();
  useEffect(() => {
    let getEnums = async () => {
      console.log(`${Config.baseUrl}/globalEnums`);
      let { data, status } = await axios.get(`${Config.baseUrl}/globalEnums`);
      if (status === 200) {
        // console.log(data);
        setEnums(data);
      }
    };
    getEnums();
  }, []);
  return (
    <>
      <ErrorToasts></ErrorToasts>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <div className="row w-100 box-login-page custom-page-wrapper">
                  <Login />
                </div>
              </>
            }
          />
          <Route
            path="index.html"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <div className="row w-100 box-login-page custom-page-wrapper">
                  <Login />
                </div>
              </>
            }
          />
          <Route
            path="/register"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <div className="row w-100 box-login-page custom-page-wrapper">
                  <Register />
                </div>
              </>
            }
          />
          <Route
            path="/register-business"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <div className="row w-100 px-5 box-login-page custom-page-wrapper">
                  <RegisterBusiness />
                </div>
              </>
            }
          />
          <Route
            path="/email-sent"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <SentEmail />
              </>
            }
          />
          <Route
            path="/phoneotp-sent"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <VerifyPhoneOtp />
              </>
            }
          />
          <Route
            path="/phone-verified"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <PhoneVerified />
              </>
            }
          />
          
          <Route
            path="/hold"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <HoldingPage />
              </>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <div className="row w-100 box-login-page custom-page-wrapper">
                  <ForgetPassword />
                </div>
              </>
            }
          />
          <Route
            path="/password-email-sent"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <PasswordEmailSent />
              </>
            }
          />
          <Route
            path="/reset-password"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <div className="row w-100 box-login-page custom-page-wrapper">
                  <ResetPassword />
                </div>
              </>
            }
          />
          <Route
            path="/*"
            element={
              <>
                <Nav notLogged={true}></Nav>
                <div className="row w-100 box-login-page custom-page-wrapper">
                  <Login />
                </div>
              </>
            }
          />
          <Route
            path="myerrand/*"
            element={
              <CustomAppShell>
                <ProtectedRoute>
                  <MyerrandNav />
                </ProtectedRoute>
              </CustomAppShell>
            }
          ></Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
