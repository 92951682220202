import hold from '../assets/images/hold.png'
function HoldingPage() {
  return (
    <div className="d-flex flex-column w-75 mx-auto mt-4">
      <img className='mx-auto' src={hold} width='400px' height='400px'></img>
      <h3 className="text-primary text-center">Thank you for registering with My Errand 🎉</h3>
        <p className="h5 text-primary text-center">
        Your Account will be verified by Admin
      </p>
      {/* <p className="h5 text-primary text-center">
      From June 15, 2022, you will be able to create Errands for our verified Partners to fulfill, so please come back then to post and have all of your Errands completed.
      </p> */}
    </div>
  );
}

export default HoldingPage;
